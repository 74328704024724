import React from 'react';
import SEO from '../components/core/meta/SEO';
import PageHeader from '../components/views/PageHeader/PageHeader';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import { graphql, useStaticQuery } from 'gatsby';
import { CurePageQuery } from '../../generated/graphql-types';
import Image from 'gatsby-image';
import 'twin.macro';
import Button from '../components/core/buttons/Button';

const CurePage = () => {
  const cureData = useStaticQuery<CurePageQuery>(graphql`
    query CurePage {
      headerBackground: file(relativePath: { eq: "headers/Rainbow.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      nonSiFerma: file(relativePath: { eq: "nonsiferma.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      roche: file(relativePath: { eq: "logo/partners/Roche.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      rocheugo: file(relativePath: { eq: "logo/rocheugo.jpg" }) {
        childImageSharp {
          fixed(height: 150) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
      rochesupp: file(relativePath: { eq: "logo/partners/rochesupp.jpg" }) {
        childImageSharp {
          fixed(height: 150) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO
        title="Servizio di accompagnamento e trasporto anziani"
        url="/lacuranonsiferma"
      />
      {/*<PageHeader*/}
      {/*  backgroundImage={cureData.headerBackground.childImageSharp.fluid}*/}
      {/*>*/}
      {/*  <Container>*/}
      {/*    <Heading variant="h1" tw="text-white">*/}
      {/*      Insieme per offrire l`accompagnamento a visite e terapie e il*/}
      {/*      supporto nelle commissioni*/}
      {/*    </Heading>*/}
      {/*  </Container>*/}
      {/*</PageHeader>*/}
      <Container tw="py-16 text-center">
        <Heading variant="h1">#Lacuranonsiferma</Heading>
        <p tw="text-paragraph mb-6">
          #Lacuranonsiferma è il progetto di Roche e UGO nato durante il periodo
          di pandemia da Covid-19 con l&apos;obiettivo di supportare pazienti in
          cura nel continuare a seguire le terapie nonostante le dfifficoltà del
          momento. Il progetto ha permesso di erogare olter 2.000 servizi
          gratuiti in tutta Italia e di affiancare 150 pazienti e le loro
          famiglie.
        </p>
        <p tw="text-paragraph mb-10">
          <b>
            Il progetto in collaborazione con Roche è terminato in data
            31/03/2021
          </b>
        </p>
        <p tw="text-3xl font-bold mb-4">Ma UGO non si ferma</p>
        <p tw="text-paragraph mb-6">
          Il servizio UGO è attivo sul territorio e continua a rappresentare un
          supporto quotidiano per pazienti e utenti con fragilità.
        </p>
        <a href="https://hellougo.typeform.com/to/M60Cma4J" target="_blank">
          <Button
            variant="filled"
            color="primary"
            tw="px-20! text-3xl! font-bold!"
          >
            Richiedi informazioni
          </Button>
        </a>

        <div tw="flex">
          <Image
            alt="roche supporto"
            tw="mx-auto max-w-full"
            fixed={cureData.rochesupp.childImageSharp.fixed}
          />
        </div>
      </Container>
    </>
  );
};

export default CurePage;
